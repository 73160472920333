<template lang="pug">
	.container
		.wallet-form-box.card
			.card-body
				.row
					.col-md-7
						h5.card-title Vianet Payment
						.font-weight-bold Pay your vianet bill.
						hr
						transition(name='fade' mode='out-in')
							.form-group(v-if='message')
								.show-message {{message}}
						transition(name='fade' mode='out-in')
							div(v-if='reports' key='1')
								.mt-4
									.font-weight-bold Customer Name
									div {{reports.CustomerName}}
								.mt-2
									.font-weight-bold Customer ID
									div {{reports.VianetCustomerId}}
								b-select.mt-3(v-model="selectedPackage" @change="changePackage")
									option( value="null" disabled) Select Package
									option( v-for="detail in reports.RenewalPlans"
										:key="detail.PlanId"
										:value="detail") {{detail.PlanName}}
								transition(name='fade')
									div(v-if='selectedPackage || payment.Amount')
										.mt-3 Total Amount to pay Rs: {{payment.Amount}}
								transition.form-group(name='fade')
									show-bank(v-if="!loading" :bankClicked="confirmBank" :banks="banks")
							.form-group(v-else key='2')
								label Customer Id
									i.fa.fa-asterisk
								input(type="text"
									class="form-control"
									v-model="payment.VianetCustomerId"
									@keyup.enter="checkBalance"
									required
									placeholder="type username and press enter"
									:disabled="loading")
								button.btn.btn-primary.mt-3(@click='checkBalance' :disabled='loading') Search
					.col-md-5.pt-5
						pin-confirm(v-if='paymentModal' :previewDetails='previewDetails' :pinVerification='verifyPin')
				loading-spinner(:loading='loading')
</template>
<script>
import ShowBank from "@/components/ShowBank";
import LoadingSpinner from "@/components/LoadingSpinner";
import PinConfirm from "@/components/PinConfirm";
import api from "@/helpers/api";
import payment from "@/mixins/payment";
import axios from "@/axios";

export default {
  mixins: [payment],
  components: { ShowBank, LoadingSpinner, PinConfirm },
  computed: {
    previewDetails() {
      return {
        "Customer Name": this.payment.VianetCustomerId,
        "Total Amount to Pay": "Rs. " + this.payment.Amount,
      };
    },
  },
  async mounted() {
    this.banks = await api.banks();
    this.payment.servicesId = await api.serviceIdBySlug("WORLDLINK");
    this.loading = false;
  },
  data: () => ({
    loading: true,
    selectedPackage: null,
    message: "",
    reports: "",
    payment: {
      VianetCustomerId: "",
      Amount: "",
      PlanId: "",
      servicesId: "",
      bank: "",
      reward: 0,
      requestType: "web",
    },
    banks: "",
  }),
  methods: {
    verifyPin(verified) {
      this.payment.pin = verified;
      this.pinVerification(this.makePayment, verified);
    },
    changePackage() {
      this.payment.Amount = this.selectedPackage.PlanAmount;
      this.payment.PlanId = this.selectedPackage.PlanId;
    },

    checkBalance() {
      if (this.payment.VianetCustomerId.length == 0) return;
      this.loading = true;
      axios
        .get("api/v2/services/vianet/checkaccount", {
          params: this.payment,
        })
        .then((r) => {
          this.loading = false;
          if (r.data && r.data.response && r.data.response.Code === "000") {
            this.reports = r.data.response;
          } else {
            this.message = "You do not have any pending bills.";
          }
        })
        .catch((e) => {
          this.loading = false;
          this.message = "Something went wrong.";
        });
    },
    makePayment() {
      if (this.pinVerified) {
        this.loading = true;
        axios.post("api/v4/vianet/payment", this.payment).then((res) => {
          if (res.data) {
            if (res.data.status === 1) {
              this.message = res.data.message;
              if (res.data.status === 1) this.$store.dispatch("UPDATE_BALANCE");
              this.pinVerified = false;
              this.reports = null;
            } else {
              this.message = res.data.message;
            }
            this.loading = false;
          }
        });
      } else {
        this.paymentModal = true;
        axios
          .get("rewards/calculate", {
            params: {
              amount: this.payment.amount,
              servicesId: this.payment.servicesId,
            },
          })
          .then((res) => {
            if (res.data && res.data.data) {
              this.loading = false;
              this.payment.reward = res.data.data.earningRewards;
            }
          });
      }
    },
    confirmBank(bank) {
      this.payment.bank = bank;
      if (this.selectedPackage) {
        this.makePayment();
      } else {
        this.message = "Select package first.";
      }
    },
  },
};
</script>
